// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl:'https://45.249.108.42:86/api/',
  apiUrl:'http://api.obicas.in/api/',
  accessToken:'EAAEzLOeuidsBO0p8CZB6p5pq6r2NJvYFp8cXMNSRctb0EUwuZC2OiPvt0wqXhLVqHaSZC3ZBS9wZCSfC6i9pC3hXnbJ1GvdGwgpnntkS4IUYLf9XzTxORzeLOhXhUgHfmkNefvVEsd6vtZCMfOAn6djSMbIUhj8Ge296i8KVuf5izZAfRmXaOgCUQZC7qtoBqPZB78yXFOTewo3Pb9fUyawwFeovtdtwZD'
};

//export const baseUrl = 'https://apis.rmlconnect.net/'


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
